import * as React from "react"
import Header from "../layout/header"
import { Helmet, } from 'react-helmet-async';
import { HelmetProvider } from 'react-helmet-async';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import '../index.scss';
//import './sites/all/themes/tickets/js/estacas/form_tickets_rd.js';
import '../sites/all/themes/tickets/js/estacas/map.js';
import '../sites/all/themes/tickets/js/estacas/cards_rd'
//import './sites/all/themes/tickets/js/estacas/stickyfloat'
import '../sites/all/themes/tickets/js/estacas/eventos'
//import '../sites/all/themes/tickets/js/estacas/form_tickets_rd'
//import './stylesheets/tickets.css'
//import './sites/all/themes/tickets/js/estacas/header_rd';
import '../sites/all/themes/tickets/js/estacas/instagram_posts';
import '../sites/all/themes/tickets/js/estacas/header_rd';
import '../sites/all/themes/tickets/js/_analyticstagmanager';
import SEO from '../components/seo'
import '../stylesheets/scss/tickets.scss'

/*
import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'GTM-MBGJKRV',
    dataLayer: {
        userId: '001',
        userProject: 'project'
    }
}
 
TagManager.initialize(tagManagerArgs)
*/
//ReactGA.initialize(app.idGA4);
//ReactGA.send("pageview");


// Send a custom event
/*ReactGA.event("send",{
  category: "prueba",
  action: "pageView",
  label: "etiqueta", // optional
  value: 99, 
});*/
const IndexPage = () => {

        //console.log = function () { };
        const scriptChunkMapping = `/*<![CDATA[*/window.___chunkMapping={{ "app": ["/app-338f6c80b582b6b32c62.js"] }};/*]]>*/`


        return (

                <body name="google" content="notranslate" className={'html front not-logged-in no-sidebars page-node page-node- page-node-1612 node-type-page lang-es'}>
                        <HelmetProvider>
                                <Helmet>

                                        <link rel="preconnect" href="https://new.lasestacas.com" />
                                        <link rel="preconnect" href="https://fonts.googleapis.com" />
                                        <link rel="preload" href="https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,700&display=swap" rel='stylesheet' type='text/css' />
                                        <link rel="preload" href="https://fonts.googleapis.com/css?family=Prompt:100,200,300,400,500,600,700|Work+Sans:100,200,300,400,500,600,700&display=swap" rel='stylesheet' type='text/css' />
                                        {/*
                                        <link rel="preconnect" href="https://fonts.gstatic.com&display=swap" />
                                        {/*} <link rel="preload" href="https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,700&display=swap" as="font" />
                                <link rel="preload" href="https://fonts.googleapis.com/css?family=Prompt:100,200,300,400,500,600,700|Work+Sans:100,200,300,400,500,600,700&display=swap" as="font" />
       
                                <link rel="preload" as="font" href="https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2
" as="font" type="font/woff2" crossorigin />
                                <link rel="preload" as="font" href="https://fonts.gstatic.com/s/prompt/v10/-W__XJnvUD7dzB2KYNodREEjew.woff2
" as="font" type="font/woff2" crossorigin />
                                <link rel="preload" as="font" href="https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2Cv_4IaWMuUZctdg.woff2
" as="font" type="font/woff2" crossorigin />
                                <link rel="preload" as="font" href="https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2C2_8IaWMuUZctdg.woff2
" as="font" type="font/woff2" crossorigin /> */}
                                        {/*<link rel="preload" as="font" href="https://fonts.gstatic.com/s/worksans/v17/QGYsz_wNahGAdqQ43Rh_fKDptfpA4Q.woff2
" as="font" type="font/woff2" crossorigin />
                                <link rel="preload" as="font" href="https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2Ck_kIaWMuUZctdg.woff2
" as="font" type="font/woff2" crossorigin />*/}
                                </Helmet>
                                {/*}
                                <GatsbySeo
                                        openGraph={{
                                                type: 'article',
                                                url: 'https://stg-rct.lasestacas.com/atracciones/hospedaje/hotel',
                                                title: 'titulo',
                                                description: 'hola que hace',
                                                images: [
                                                        {
                                                                url: "https://lasestacas.com/sites/default/files/sites/le_platilla_galeriahotel_1.jpg",
                                                                width: 800,
                                                                height: 600,
                                                                alt: 'Og Image Alt',
                                                        }
                                                ],
                                        }}
                                />*/}
                        </HelmetProvider>
                        <Header />
                </body>
        )
}

export default IndexPage
